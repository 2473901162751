import { lazy } from "react";
// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";
import AddRequest from "views/utilities/request";
// dashboard routing
const Dashboard = Loadable(lazy(() => import("views/dashboard/Default")));


//Banner Route
const Banner = Loadable(lazy(() => import("views/utilities/Banner/banner")));
const AddBanner = Loadable(lazy(() => import("views/utilities/Banner/add-banner")));
const EditBanner = Loadable(
  lazy(() => import("views/utilities/Banner/edit-banner"))
);


const News = Loadable(lazy(() => import("views/utilities/news/index")));
const AddNews = Loadable(lazy(() => import("views/utilities/news/add-news")));
const EditNews = Loadable(lazy(() => import("views/utilities/news/edit-news")));

// for transaction

const Transaction = Loadable(lazy(() => import("views/utilities/transactionHistory/transaction-list")));
const AddTransaction = Loadable(lazy(() => import("views/utilities/transactionHistory/add-transaction")));
const EditTransaction = Loadable(lazy(() => import("views/utilities/transactionHistory/edit-transaction")));


//for user

const User = Loadable(lazy(() => import("views/utilities/user/user-list")));
const AddUser = Loadable(lazy(() => import("views/utilities/user/add-user")));
const EditUser = Loadable(lazy(() => import("views/utilities/user/edit-user")));

//for category

const CategoryList = Loadable(
  lazy(() => import("views/utilities/category/showCategory"))
);

const AddCategory = Loadable(
  lazy(() => import("views/utilities/category/addCategory"))
);

const EditCategory = Loadable(
  lazy(() => import("views/utilities/category/editCtegory"))
);

const ViewMember = Loadable(
  lazy(() => import("views/utilities/transactionHistory/view-member-list"))
);

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "/dashboard",
      element: <Dashboard />,
    },

    {
      children: [
        {
          path: "category",
          element: <CategoryList />,
        },
        {
          path: "add-category",
          element: <AddCategory />,
        },
        {
          path: "edit-category/:id",
          element: <EditCategory />,
        },
        ,
        {
          path: "view-voter/:id",
          element: <ViewMember />,
        },
      
      ],
    },
    {
      children: [
        {
          path: "banner",
          element: <Banner />,
        },
        {
          path: "add-banner",
          element: <AddBanner />,
        },
        {
          path: "edit-banner/:id",
          element: <EditBanner />,
        },
      ],
    },
    {
      children: [
        {
          path: "news",
          element: <News />,
        },
        {
          path: "add-news",
          element: <AddNews />,
        },
        {
          path: "edit-news/:id",
          element: <EditNews />,
        },
      ],
    },
    {
      path: "/requested-news",
      element: <AddRequest />,
    },
    {
      children: [
        {
          path: "user",
          element: <User />,
        },
        {
          path: "add-user",
          element: <AddUser />,
        },
        {
          path: "edit-user/:id",
          element: <EditUser />,
        },
      ],
    },
    {
      children: [
        {
          path: "transaction",
          element: <Transaction />,
        },
        {
          path: "add-transaction",
          element: <AddTransaction />,
        },
        {
          path: "edit-transaction/:id",
          element: <EditTransaction />,
        },
      ],
    },
  ],
};

export default MainRoutes;
