export const HttpMethod = {
  Get: "GET",
  Post: "POST",
  Put: "PUT",
  Patch: "PATCH",
  Delete: "DELETE",
};

const ApiRoutes = {
  Dashboard: {
    Data: {
      Endpoint: "/auth/dashboard",
      Method: HttpMethod.Post,
    },
  },
  Auth: {
    Login: {
      Endpoint: "/auth/login",
      Method: HttpMethod.Post,
    },
  },
  
  Banner: {
    All: {
      Endpoint: "/home/getbanner",
      Method: HttpMethod.Post,
    },
    
    AddBanner: {
      Endpoint: "/home/addbanner",
      Method: HttpMethod.Post,
    },
    EditBanner: {
      Endpoint: "/home/updatebanner",
      Method: HttpMethod.Post,
    },
    DeleteBanner: {
      Endpoint: "/home/deletebanner",
      Method: HttpMethod.Post,
    },
  },
  Category: {
    All: {
      Endpoint: "/categories/getcategory",
      Method: HttpMethod.Post,
    },
   
    AddCategory: {
      Endpoint: "/categories/addcategory",
      Method: HttpMethod.Post,
    },
    
    DeleteCategory: {
      Endpoint: "/categories/deletecategory",
      Method: HttpMethod.Post,
    },
    EditCategory: {
      Endpoint: "/categories/updatecategory",
      Method: HttpMethod.Post,
    },
    getCategoryById: {
      Endpoint: "/categories/getCategoryById",
      Method: HttpMethod.Post,
    },
   
  },
  News: {
    All: {
      Endpoint: "/news/getallnews",
      Method: HttpMethod.Post,
    },
    NewsById: {
      Endpoint: "News/getNewsById",
      Method: HttpMethod.Post,
    },
    AddNews: {
      Endpoint: "news/addNews",
      Method: HttpMethod.Post,
    },
    EditNews: {
      Endpoint: "news/updateNews",
      Method: HttpMethod.Post,
    },
    DeleteNews: {
      Endpoint: "news/deleteNews",
      Method: HttpMethod.Post,
    },
  },
};

export default ApiRoutes;
