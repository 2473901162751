import { configureStore } from "@reduxjs/toolkit";
import userReducer from "../redux-slice/user.slice";
import customizationReducer from "store/customizationReducer";
import memberListReducer from "../redux-slice/member-list.slice";
import categoryReducer from "../redux-slice/category.slice";
import newsListReducer from "../redux-slice/news.slice";
export const store = configureStore({
  reducer: {
    user: userReducer,
    customization: customizationReducer,
    member: memberListReducer,
    news: newsListReducer,
    category:categoryReducer,
    
  },
});
