// assets
import {
  IconArticle,
  IconMap2,
  IconBrandBlogger,
  IconListNumbers,
  IconUsers,
  IconChecklist,
  IconCategory,
  IconBrandTrello,
  IconNews,
  IconArrowsTransferUp,
} from "@tabler/icons";

// constant
const icons = {
  IconMap2,
  IconArticle,
  IconBrandBlogger,
  IconListNumbers,
  IconUsers,
  IconChecklist,
  IconCategory,
  IconBrandTrello,
  IconNews,
  IconArrowsTransferUp,
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const utilities = {
  id: "utilities",
  title: "Utilities",
  type: "group",
  children: [
   
    {
      id: "category",
      title: "Category",
      icon: icons.IconCategory,
      type: "collapse",
      children: [
        {
          id: "category",
          title: "Category List",
          type: "item",
          url: "/category",
          breadcrumbs: true,
        },
        {
          id: "add-category",
          title: "Add Category",
          type: "item",
          url: "/add-category",
          breadcrumbs: true,
        },
        
        
      ],
    },
    {
      id: "banner",
      title: "Banner",
      icon: icons.IconBrandTrello,
      type: "collapse",
      children: [
        {
          id: "banner",
          title: "Banner",
          type: "item",
          url: "/banner",
          breadcrumbs: true,
        },
        {
          id: "add-banner",
          title: "Add Banner",
          type: "item",
          url: "/add-banner",
          breadcrumbs: true,
        },
      ],
    },
    {
      id: "news",
      title: "News",
      icon: icons.IconNews,
      type: "collapse",
      children: [
        {
          id: "news-list",
          title: "News List",
          type: "item",
          url: "/news",
          breadcrumbs: true,
        },
        {
          id: "add-news",
          title: "Add News",
          type: "item",
          url: "/add-news",
          breadcrumbs: true,
        },
      ],
    },
    {
      id: "request",
      title: "Request",
      icon: icons.IconNews,
      type: "collapse",
      children: [
        {
          id: "request",
          title: "Requested News",
          type: "item",
          url: "/requested-news",
          breadcrumbs: true,
        },
        
      ],
    },
    {
      id: "user",
      title: "User",
      icon: icons.IconUsers,
      type: "collapse",
      children: [
        {
          id: "user-list",
          title: "User",
          type: "item",
          url: "/user",
          breadcrumbs: true,
        },
        {
          id: "add-user",
          title: "Add User",
          type: "item",
          url: "/add-user",
          breadcrumbs: true,
        },
      ],
    },
   
        {
          id: "transaction-list",
          title: "Transaction History",
          type: "item",
          icon: icons.IconArrowsTransferUp,
          url: "/transaction",
          breadcrumbs: true,
        },
        
  ],
};

export default utilities;
